import styled from "@emotion/styled"
import React from "react"
import { navSubLinks } from "./navLinks"
import ServiceDisplay from "./ServiceDisplay"

const services = [
  "oral dentistry",
  "extractions",
  "cosmetic dentistry",
  "procedures",
]

const Section = styled.section`
  margin: 128px auto;
  display: grid;
  gap: 64px 24px;
  max-width: 1064px;
  grid-template-columns: 1fr;
  justify-items: center;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`

export default function ServicesGridDisplay() {
  return (
    <Section>
      {services.map(service =>
        navSubLinks[service].map((subService, i) => (
          <ServiceDisplay {...subService} category={service} key={i} />
        ))
      )}
    </Section>
  )
}
