import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import rightArrow from "../images/right-arrow.png"

const Box = styled.div`
  display: flex;
  align-items: flex-end;
  background: ${props =>
    `linear-gradient(to bottom, ${props.theme.darkBlue} 80%, transparent 80%, transparent 100%)`};
  height: 390px;
  width: 472px;
  max-width: 100%;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 247px;
    width: 50px;
    height: 50px;
    background: ${props => `url(${rightArrow}),
      linear-gradient(
        to bottom,
        ${props.theme.blue} 0%,
        ${props.theme.blue} 100%
      )`};
    background-position: center;
    background-repeat: no-repeat;
  }

  img {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 67px;
    max-height: 82px;
  }

  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-top: 5px;
    letter-spacing: -1px;
  }

  p {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 0.75rem;
    color: ${props => props.theme.lightGrey};
    font-weight: 700;
  }

  @media (min-width: 1024px) {
    height: 520px;
    max-width: 630px;

    ::after {
      top: 346px;
      right: 19px;
    }

    img {
      top: 160px;
      max-width: 90px;
      max-height: 110px;
    }

    h3 {
      margin-top: 15px;
    }
  }
`

export default function ServiceDisplay(props) {
  const { link, img, text, category } = props

  return (
    <Link to={`/${category.replace(/\s/g, "-")}/${link}`}>
      <Box>
        <img src={img} alt={text} />
        <div>
          <p>{category}</p>
          <h3>{text}</h3>
        </div>
      </Box>
    </Link>
  )
}
