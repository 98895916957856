import React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"
import ServicesGridDisplay from "../components/ServicesGridDisplay"

export default function ServicesPage() {
  return (
    <Layout learnMore servicesPage>
      <Seo title="All Services - Pikes Peak Oral Surgery & Dental Implant Center | Dental Implants and Wisdom Teeth Removals in Colorado Springs, CO" />
      <ServicesGridDisplay />
    </Layout>
  )
}
